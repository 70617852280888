import NavLink from "@/component/Navlink";
import SVG from "react-inlinesvg";
import { useState, useEffect, useRef } from "react";
import sendGAEvent from "@/utils/gaEvent";
// import { useRouter } from "next/router";

const SliderMenu = ({ header_menu = [] }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [, forceUpdate] = useState(null);
  const [lastActiveId, setLastActiveId] = useState();
  // const router = useRouter();

  const menuRefs = useRef([]);
  const containerRef = useRef(null);
  useEffect(() => {
    const handleClick = (event) => {
      // Handle the click event here
      // if (activeDropdown === lastActiveId) {
      // } else
      if (activeDropdown !== null) {
        setActiveDropdown(null);
      } else if (event?.target?.id?.includes(lastActiveId)) {
        setActiveDropdown(lastActiveId);
      }
    };

    // Add click event listener when the component mounts
    document.addEventListener("click", handleClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [activeDropdown]);

  useEffect(() => {
    const handleScroll = () => {
      forceUpdate({});
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    menuRefs.current = menuRefs.current.slice(0, header_menu.length);
  }, [header_menu]);

  const getPosition = (index) => {
    const element = menuRefs.current[index];
    if (!element) return 0;
    // { top: 0, left: 0 }
    // const containerRect = containerRef.current.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    // console.log(elementRect.left, containerRect.left, scrollX)
    return elementRect.left;
    // {
    //   top: elementRect.top - containerRect.top,
    //   left: elementRect.left - containerRect.left - scrollX
    // };
  };

  return (
    <div className="relative">
      {/* Higher-level relative container */}
      <div className="flex overflow-x-auto py-1 space-x-2" ref={containerRef}>
        {header_menu.map((item, index) => (
          <div
            key={item.id}
            className="group"
            ref={(el) => (menuRefs.current[index] = el)}
            id={"dropdownHoverButton_head_" + item?.id}
          >
            {item?.sub_menu?.length > 0 ? (
              <div
                id={"dropdownHoverButton_" + item?.id}
                //href={item?.canonical_url}
                title={item?.title}
                exact={true}
                key={item?.id}
                onClick={() => {
                  let eventName = `Top Bar - ${item?.title} Click`;
                  sendGAEvent({
                    eventName: eventName,
                    pageTitle: document?.title,
                    pageLocation: window?.location?.href,
                  });
                }}
                onMouseEnter={() => {
                  setActiveDropdown(item.id);
                  setLastActiveId(item.id);
                }}
                className={`px-2 bg-white text-black min-w-fit flex items-center whitespace-nowrap hover:text-primary`}
                data-dropdown-toggle={"dropdownHover_" + item?.id}
              >
                {item?.svg && (
                  <div className="w-4 h-4 mx-1">
                    <SVG className="w-4 h-4" src={item?.svg} />
                  </div>
                )}
                <a href={item?.canonical_url}>{item?.title}</a>
                {item?.sub_menu?.length > 0 && (
                  <svg
                    className="w-2 h-2 ml-4 inline"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                    onMouseEnter={() => {
                      setActiveDropdown(item.id);
                      setLastActiveId(item.id);
                    }}
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                )}
              </div>
            ) : (
              <NavLink
                id={"dropdownHoverButton_" + item?.id}
                href={item?.canonical_url}
                title={item?.title}
                exact={true}
                key={item?.id}
                onMouseEnter={() => {
                  setActiveDropdown(item.id);
                  setLastActiveId(item.id);
                }}
                onClick={() => {
                  let eventName = `Top Bar - ${item?.title} Click`;
                  sendGAEvent({
                    eventName: eventName,
                    pageTitle: document?.title,
                    pageLocation: window?.location?.href,
                  });
                }}
                // onMouseLeave={() => setActiveDropdown(null)}
                className={`px-2 bg-white text-black min-w-fit flex items-center whitespace-nowrap hover:text-primary`}
                data-dropdown-toggle={"dropdownHover_" + item?.id}
              >
                {item?.svg && (
                  <div className="w-4 h-4 mx-1">
                    <SVG className="w-4 h-4" src={item?.svg} />
                  </div>
                )}

                {item?.title}
              </NavLink>
            )}
          </div>
        ))}
      </div>
      {activeDropdown &&
        header_menu.map(
          (item, index) =>
            activeDropdown === item.id &&
            item?.sub_menu?.length > 0 && (
              <div
                key={item.id}
                style={{
                  position: "absolute",
                  left: `${getPosition(index)}px`,
                  width: "120px",
                }}
                className="overflow-y-auto max-h-52 bg-white divide-y divide-gray-200 rounded-bl-lg rounded-br-lg shadow-lg subcategory-menu"
                id={"dropdownHover_" + item?.id}
              >
                <ul
                  className="py-1 text-gray-700 dark:text-gray-200"
                  aria-labelledby={"dropdownHoverButton_" + item?.id}
                >
                  {item?.sub_menu?.map((sub_item, index2) => (
                    <a
                      key={index2}
                      onClick={() => {
                        let eventName = `Top Bar - ${item?.title} - ${sub_item.title} Click`;
                        sendGAEvent({
                          eventName: eventName,
                          pageTitle: document?.title,
                          pageLocation: window?.location?.href,
                        });
                      }}
                      href={
                        sub_item?.canonical_url ? sub_item.canonical_url : ""
                      }
                      style={{ cursor: "pointer" }}
                      // onClick={() => setIsDrawerOpen(false)}
                      className="block px-4 py-2 font-medium hover:bg-primary-300"
                    >
                      <li>{sub_item?.title}</li>
                    </a>
                  ))}
                </ul>
              </div>
            )
        )}
    </div>
  );
};

export default SliderMenu;
